<template>
  <div class="page">
    <template v-if="user.user_type === CORPCLIENT">
      <ClientCalcCompliance :userId="user.id" userType='CorpClient'/>
    </template>

    <template v-else>
      Эта страница есть только у корпоративных клиентов
    </template>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CORPCLIENT } from '@/common/constants/userTypes';
import ClientCalcCompliance from '@/components/clients/profile/ClientCalcCompliance';

export default {
  name: 'Profile',
  components: {
    ClientCalcCompliance
  },
  provide: {
    isProfile: true,
  },
  data: () => ({
    CORPCLIENT
  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  methods: {
    ...mapActions({
    }),
  },
  created() {
  },
  beforeDestroy() {
  },
};
</script>
